import { API } from "../config";

export const transfer = () => {
    return fetch(`${process.env.REACT_APP_API_URL}/transaction/transfer`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      // body: JSON.stringify(user)
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        console.log(err);
      });
};

